<template>
<div class="wrapper">
  <h4>{{ titleName }}</h4>
  <el-form :model="form" ref="form" :rules="rules" label-width="auto">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="企业名称" prop="compName">
          <el-input v-model="form.compName" placeholder="请输入企业名称" auto-complete="off" :disabled="detailDis"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="企业类型"  >
           <el-select v-model="form.companyType" placeholder="请选企业类型" style="width:100%;" :disabled="detailDis">
              <el-option v-for="item in companyTypeList" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></el-option>
            </el-select>
          <!-- <el-input v-model="form.companyType" placeholder="请输入办公地址" auto-complete="off"></el-input> -->
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="办公地址">
          <el-input v-model="form.compAddres" placeholder="请输入办公地址" auto-complete="off" :disabled="detailDis"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
          <el-form-item  label="联系方式">
            <el-input v-model="form.contPhone" placeholder="请输入联系方式" auto-complete="off" :disabled="detailDis"></el-input>
          </el-form-item>
        </el-col>
       <el-col :span="8">
        <el-form-item  label="服务区域">
          <el-input v-model="form.serviceArea" placeholder="请输入服务区域" auto-complete="off" :disabled="detailDis"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="联系人">
          <el-input v-model="form.contPerson" placeholder="请输入联系人" auto-complete="off" :disabled="detailDis"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="经度" prop="longitude">
          <el-input v-model="form.longitude" placeholder="请输入经度" auto-complete="off"  @input="(v)=>(form.longitude=v.replace(/[^\d.]/g,''))" :disabled="detailDis"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="纬度" prop="latitude">
          <el-input v-model="form.latitude" placeholder="请输入纬度"  @input="(v)=>(form.latitude=v.replace(/[^\d.]/g,''))" auto-complete="off" :disabled="detailDis"></el-input>
        </el-form-item>
      </el-col>
    </el-row>


    <el-row :gutter="20">
      <el-col :span="8">
        <el-form-item  label="签约开始日期">
          <el-date-picker
          :disabled="detailDis"
            style="width:100%;"
            v-model="form.sigFromDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item  label="签约结束日期">
          <el-date-picker
          :disabled="detailDis"
            style="width:100%;"
            v-model="form.sigToDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16">
          <el-form-item  label="企业简介">
          <el-input :disabled="detailDis" v-model="form.introduction" placeholder="请输入企业简介" auto-complete="off" type="textarea"  maxlength="500" show-word-limit  :autosize="{ minRows:6, maxRows:6}"  resize='none'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
        <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item :disabled="detailDis" label="营业执照、组织机构代码证、法人身份证（正反面）" prop="imgs" label-width="200" inline class="img">
            <upload-img :limit="6" :uploadLists="imgsList" @uploadChange="uploadSuccess2"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>


    <el-row>
      <el-col :span="24">
        <el-form-item style="text-align: center;" label-width="0">
          <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')" v-if="!detailDis">保存</el-button>
          <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>

<script>
import { mapActions} from "vuex";
import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from 'axios'



export default {
  name: 'AddOrEdit',
    components: {
    UploadImg,
  },
  data() {
    return {
      titleName: '',
      form: {},
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      headers: { Authorization: sessionStorage.token },
      uploadApi: process.env.NODE_ENV === 'development' ? '/api' + api.fileUpload : process.env.VUE_APP_BASEURL + api.fileUpload,
      
      imgsList:[],
      companyTypeList:[],
      rules: {
          compName: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
          ],
          longitude: [
            { required: true, message: '请输入经度', trigger: 'blur' },
          ],
          latitude: [
            { required: true, message: '请输入纬度', trigger: 'blur' },
          ],
      },
      imgList:[],
      dialogVisible:false,
      dialogImageUrl:'',
      detailDis:false
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    ...mapActions([
      "addServeOrgsServe",
      "updateOrgsServe",
      'getSysDictServe',
    ]),
    getDicList() {
      this.getSysDictList('company_type', 'companyTypeList')

    },
        // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加服务企业'
      } else if (this.routeType == 'edit'){
        this.titleName = '修改服务企业'
        this.itemData = JSON.parse(this.$route.query.item);
        console.log(this.itemData );
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          compName: this.itemData.compName,
          companyType:this.itemData.companyType,
          compAddres: this.itemData.compAddres,
          contPerson: this.itemData.contPerson,
          serviceArea:this.itemData.serviceArea,
          contPhone: this.itemData.contPhone,
          sigFromDate: this.itemData.sigFromDate,
          sigToDate: this.itemData.sigToDate,
          longitude: this.itemData.longitude,
          latitude: this.itemData.latitude,
          id: this.itemData.id,
          introduction:this.itemData.introduction,
          relatedImgs: this.itemData.relatedImgs,
        }
         if (this.itemData.relatedImgs) {
            this.getFileDetail2(this.itemData.relatedImgs)
          }
      }
      else if (this.routeType == 'detail'){
        this.titleName = '服务企业详情'
        this.detailDis = true
        this.itemData = JSON.parse(this.$route.query.item);
        console.log(this.itemData );
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          compName: this.itemData.compName,
          companyType:this.itemData.companyType,
          compAddres: this.itemData.compAddres,
          contPerson: this.itemData.contPerson,
          serviceArea:this.itemData.serviceArea,
          contPhone: this.itemData.contPhone,
          sigFromDate: this.itemData.sigFromDate,
          sigToDate: this.itemData.sigToDate,
          longitude: this.itemData.longitude,
          latitude: this.itemData.latitude,
          id: this.itemData.id,
          introduction:this.itemData.introduction,
          relatedImgs: this.itemData.relatedImgs,
        }
         if (this.itemData.relatedImgs) {
            this.getFileDetail2(this.itemData.relatedImgs)
          }
      }
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.imgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = this.form
          let server = this.routeType == 'add' ? "addServeOrgsServe" : "updateOrgsServe"
          this[server](params).then(res => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: "success"
              });
              this.$router.go(-1)
            }
            this.loading = false;
          })
        } else {
          return false;
        }
      });
    },
    uploadSuccess(){},
    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      uploadSuccess(response, file, fileList){
        console.log(response, file, fileList);

      },
      beforeUpload(file){
        console.log('文件上传前',file);

      },
      uploadSuccess2(data) {
        console.log(data);
        this.form.relatedImgs = data.join(',')
        console.log(this.form.imgs);
    },

  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
      height: 200px;
      overflow: auto;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #dcdfe6;
	  padding: 0 8px;
      li {
        height: 32px;
        line-height: 32px;
        cursor: pointer;
      }
    }
}
::v-deep .img .el-form-item__label{
display: contents
}
</style>
